import { theme } from "theme";
import TranslateParagraph from "./TranslateParagraph";
import { TypographyProps } from "@mui/material";

type ErrorParagraphProps = TypographyProps & {
  error?: String;
  inputFieldError?: boolean;
};

export const ErrorParagraph = (props: ErrorParagraphProps) => {
  const { error, inputFieldError, ...restProps } = props;
  const sx = inputFieldError
    ? {
        margin: 0,
        color: theme.palette.error.main,
        fontWeight: "bold",
        fontSize: "0.75rem",
        letterSpacing: "0.03333em",
        display: "flex",
        ...props.sx,
      }
    : {
        color: theme.palette.error.main,
        display: "flex",
        ...props.sx,
      };

  return <TranslateParagraph text={error} sx={sx} {...restProps} />;
};
