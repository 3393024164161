import { CHAMP_REQUIS, MESSAGE_ERROR_HEURES_DOIVENT_ETRE_A_0 } from "utils/constantes";
import { z, ZodErrorMap } from "zod";

export const dateFormat = "dd/MM/yyyy";
export const dateFormatShort = "MM/yyyy";
export const VALEUR_NON_DEFINI = "N/A";
export const NA_FOR_ENUM = "NA";

export const required = { required_error: CHAMP_REQUIS };

export const requiredEnum: { errorMap?: ZodErrorMap } = {
  errorMap: (issue, ctx) => {
    if (issue.code === "invalid_enum_value")
      return {
        message: `La valeur reçu "${
          issue.received
        }" est invalide. Le format attendu est ${issue.options.join(" ou ")}`,
      };

    if (issue.code === "invalid_type") return { message: required["required_error"] };

    return { message: ctx.defaultError };
  },
};

export const civiliteEnum = ["MR", "MM", "ML", NA_FOR_ENUM] as const;
export const labelsCivilite = {
  MR: "Monsieur",
  MM: "Madame",
  ML: "Mademoiselle",
  NA: "",
} as const;

export const zCivilite = z.enum(civiliteEnum, requiredEnum);

const statutContrat = ["Tous", "C", "R"] as const;
export const labelsStatutContrat = {
  Tous: "Tous",
  C: "En Cours",
  R: "Résilié",
};

export const zStatutContrat = z.enum(statutContrat, requiredEnum);
export const zOpearation = z.enum(["I", "R"], requiredEnum);

const origineResiliationEnum = [
  "NC",
  "NP",
  "AS",
  "AU",
  "FA",
  "FS",
  "IP",
  "IR",
  "AR",
  "OC",
  "PS",
] as const;
export const labelsOrigineResiliation = {
  NC: "Nullité Contrat",
  NP: "Non Paiement",
  AS: "Après Sinistre",
  AU: "Autres",
  FA: "Du fait Assure",
  FS: "Fréquence de sinistres",
  IP: "Fréquence incidents de paiement",
  IR: "Inadéquation du risque",
  AR: "Changement de politique d'acceptation des risques",
  OC: "Non respect des obligations contractuelles",
  PS: "Perte de qualité societaire",
  NA: "",
};

export const canalEnum = ["All", "WS", "PORTAIL"] as const;

export const typeInterrosEnum = [
  "ALL",
  "INTERROGATION_CONFORMITE",
  "INTERROGATION_INFORMATION_PARTICULIER",
  "INTERROGATION_INFORMATION_SOCIETE",
] as const;
export const typeInterrosLabels = {
  ALL: "Tous",
  INTERROGATION_CONFORMITE: "Interrogation Conformité",
  // INTERROGATION_INFORMATION_PARTICULIER: "Interrogation Paticulier",
  // INTERROGATION_INFORMATION_SOCIETE: "Interrogation Société",
};

export const typeAlimEnum = [
  "ALL",
  "ALIMENTATION_RIE",
  "ALIMENTATION_CRM",
  "ALIMENTATION_HABILITATION",
] as const;

export const statutFileEnum = ["Tous", "EN_ATTENTE", "EN_COURS", "TERMINE", "EN_ERREUR"] as const;
export const statutFileLabels = {
  Tous: "Tous",
  EN_ATTENTE: "En Attente",
  EN_COURS: "En cours",
  TERMINE: "Terminé",
  EN_ERREUR: "En erreur",
};

export const zOrigineResiliation = z.enum(origineResiliationEnum, requiredEnum);

export const USAGE_ENUM = ["T", "D", NA_FOR_ENUM] as const;
export const labelUsage = {
  T: "Usage tournées",
  D: "Usage tous déplacements",
  NA: VALEUR_NON_DEFINI,
};
export const zUsageVehicule = z.enum(USAGE_ENUM);
export const zMotifAlimentation = z.enum(["A", "E", "T"], requiredEnum);

export const labelTypeConducteur = {
  P: "Principal",
  S: "Secondaire",
  O: "Occasionnel",
  D: "Non désigné",
  I: "Indéterminé",
};

export const zTypeConducteur = z.enum(["P", "S", "O", "D", "I"], requiredEnum);
export const labelNatureSinistre = {
  M: "Matériel",
  C: "Corporel",
};
export const zNatureSinitre = z.enum(["M", "C"], requiredEnum);

export const labelsPrecisionSinistre = {
  "5": "Bris de Glace ",
  "3": "Incendies ",
  H: "Vols",
  J: "Vandalisme",
  RC: "RC",
  DM: "Dommages",
  AS: "Assistance",
  "9": "Indeterminé",
};
export const zPrecisionSinistre = z.enum(["5", "3", "H", "J", "RC", "DM", "AS", "9"], requiredEnum);
export const labelPartResponsabilite = {
  N: "Nulle",
  T: "Totale",
  P: "Partielle",
  I: "Inconnue",
};
export const zPartResponsabilite = z.enum(["N", "T", "P", "I"], requiredEnum);

export const zDateHours0 = z.coerce.date(required).refine(
  (date) => {
    const hours = date?.getHours();
    const min = date?.getMinutes();
    const sec = date?.getSeconds();
    return hours === 0 && min === 0 && sec === 0;
  },
  { message: MESSAGE_ERROR_HEURES_DOIVENT_ETRE_A_0 }
);

export enum EtatAlerteEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  Tous = "Tous",
}

export const etatAlerteEnum = [EtatAlerteEnum.Tous, EtatAlerteEnum.ACTIVE, EtatAlerteEnum.INACTIVE] as const;
export const zEtatAlertes = z.enum(etatAlerteEnum, requiredEnum);

export enum StatutAlerteEnum {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  Tous = "Tous",
}
export const statutAlerteEnum = [StatutAlerteEnum.Tous, StatutAlerteEnum.ACTIVE, StatutAlerteEnum.INACTIVE] as const;
export const zStatutAlerte = z.enum(statutAlerteEnum, requiredEnum);

export const codeAlerteEnum = ["Tous", "PBDELAI", "INTINCG", "INTINCS", "INTRESG", "INTRESS", "NPFVA", "RIPAR"] as const;
export const zCodeAlerte = z.enum(codeAlerteEnum, requiredEnum);
