import {createTheme} from "@mui/material/styles";

export const theme = createTheme({
    palette: {
        primary: {
            light: "#C2D2FB",
            main: "#011C62",
        },
        secondary: {
            main: "rgba(1, 28, 98, 0.26)",
            dark: "#7481A3",
        },
        background: {
            default: "#F7F7F7",
        },
    },
    typography: {
        h1: {
            fontSize: 20,
            fontWeight: "bold",
        },
        h2: {
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 12,
            marginTop: 8,
            paddingLeft: 2,
            color: "primary.dark",
        },
        h3: {
            fontSize: 20,
            fontWeight: "bold",
            textTransform: "capitalize",
        },
    },
});
